import dayjs from 'dayjs';
import numeral from 'numeral';
import { useRememberStore } from '@/store/remember.js';

export default class General {

    /* *********************************** *
     *   ╔═════════════════════════════╗   *
     *   ║   DATE AND TIME FUNCTIONS   ║   *
     *   ╚═════════════════════════════╝   *
     * *********************************** */

    formatDate(date = this.getToday(), format = 'YYYY-MM-DD', clearTime = false) {
        if (date === '0000-00-00' || date === null) {
            return '';
        }

        if (clearTime) {
            return dayjs(date).set('hour', 0).set('minute', 0).set('second', 0).format('YYYY-MM-DD HH:mm:ss');
        } else {
            return dayjs(date).format(format);
        }
    };

    formatHours(hour, showLeadingZero = false) {
        let hours = parseFloat(JSON.parse(JSON.stringify(hour)));
        const minutes = (hours % 1);
        hours -= minutes;

        if (isNaN(hours) && isNaN(minutes)) {
            return '0h00';
        }

        return `${hour < 0 ? '-' : ''}${(showLeadingZero && hours < 10) ? '0' : ''}${Math.abs(hours).toLocaleString()}h${dayjs.duration({ minutes: Math.abs(minutes * 60).toFixed(0) }).format('mm')}`;
    };

    convertUtcToLocale(date) {
        return this.formatDate(dayjs.utc(date).tz('America/Montreal'), 'YYYY-MM-DD HH:mm:ss');
    }

    getUserTimezone() {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    };
    convertFromUserTimezone() {
        return dayjs.tz(this.getToday(true), this.getUserTimezone()).tz('America/Montreal');
    };

    // ISO start week monday
    getWeekNumber(date) {
        return (date === '0000-00-00' || date === null) ? '' : dayjs(date).isoWeek();
    };

    getWeekDay(date = this.getToday()) {
        return dayjs(date).day();
    };

    getToday(withTime = false) {
        return (!withTime) ? dayjs().format('YYYY-MM-DD') : dayjs().format('YYYY-MM-DD HH:mm:ss');
    };

    getByWeekday(date = this.getToday(), day) {
        return this.formatDate(dayjs(date).weekday(day));
    };

    getMonths() {
        return dayjs.months();
    };

    getDateStartAndEnd(date_entry) {
        const date = dayjs(date_entry);
        const dayOfWeek = date.isoWeekday();
        let period = {
            date_start: null,
            date_end: null,
            weekNumber: this.getWeekNumber(date),
        };

        if (dayOfWeek === 1) {
            period.date_start = date;
        } else {
            const daysUntilMonday = 1 - dayOfWeek;
            period.date_start = date.add(daysUntilMonday, 'day');
        }

        period.date_end = period.date_start.add(6, 'day');

        period.date_start = period.date_start.format('YYYY-MM-DD');
        period.date_end = period.date_end.format('YYYY-MM-DD');

        return period;
    };

    getTimesheetLockLimit(date) {
        return dayjs(date).add(dayjs.duration({ 'days': 1, 'hours': 12 })).format('YYYY-MM-DD HH:mm:ss');
    };

    getTotalMinutes = (h, m) => {
        return (h * 60) + m;
    };

    // action = add || subtract
    getFromDate(date = this.getToday(), action, value, units) {
        let dateModified = ((action === 'add') ? dayjs(date).add(value, units) : dayjs(date).subtract(value, units));
        return this.formatDate(dateModified);
    };

    getDiff(date = this.getToday(), compare = this.getToday(), units) {
        return dayjs(date).diff(dayjs(compare), units);
    }

    setDate(date, value, units, format = undefined) {
        return this.formatDate(dayjs(date).set(units, value), format);
    }

    addBusinessDay(nbDays, date = this.getToday()) {
        return this.formatDate(dayjs(date).businessDaysAdd(nbDays));
    };

    getStartOfWeek(date = this.getToday()) {
        return dayjs(date).startOf('week');
    };

    getStartOfLastWeek() {
        return this.formatDate(dayjs().subtract(1, 'week').startOf('week'));
    };

    getEndOfLastWeek() {
        return this.formatDate(dayjs().subtract(1, 'week').endOf('week'));
    };

    getDuration(time, units) {
        return dayjs.duration(time, units);
    }

    // Parameter 4 is a string with two characters; '[' means inclusive, '(' exclusive
    isDateBetween(date, date_start, date_end, include) {
        return (dayjs(date).isBetween(date_start, date_end, 'day', include));
    };

    isDateSame(date, compare = this.getToday(), units = 'day') {
        return dayjs(date).isSame(dayjs(compare), units);
    };

    isDateSameOrBefore(date, dateCompare = this.getToday()) {
        return (dayjs(date).isSameOrBefore(dateCompare));
    };

    isDateBefore(date, dateCompare = this.getToday()) {
        return (dayjs(date).isBefore(dateCompare));
    };

    isDateSameOrAfter(date, dateCompare = this.getToday()) {
        return (dayjs(date).isSameOrAfter(dateCompare));
    };

    isDateAfter(date, dateCompare = this.getToday()) {
        return (dayjs(date).isAfter(dateCompare));
    };

    isWeekend(date = this.getToday()) {
        return [0, 6].includes(this.getWeekDay(date));
    }

    isValidDate(value) {
        const dateObject = new Date(value);
        return dateObject.toString() !== 'Invalid Date';
    };

    areTimeEntriesValid(timeEntry, entryList, indexEntry = -1) {
        if (entryList === undefined) { return 0; }

        const [hFromEntry, mFromEntry] = timeEntry.hour_from.split(/[:hH]/).map(val => parseInt(val));
        const [hToEntry, mToEntry] = timeEntry.hour_to.split(/[:hH]/).map(val => parseInt(val));
        let invalidCount = 0;

        entryList.forEach((entry, index) => {
            if (index === indexEntry || entry.isDeleted) {
                return;
            }

            const [hFrom, mFrom] = entry.hour_from.split(/[:hH]/).map(val => parseInt(val));
            const [hTo, mTo] = entry.hour_to.split(/[:hH]/).map(val => parseInt(val));

            if (dayjs(timeEntry.date).isSame(dayjs(entry.date), 'day') &&
                (
                    (this.isEqual(hFromEntry, hFrom) && this.isEqual(mFromEntry, mFrom)) ||
                    (this.isEqual(hToEntry, hTo) && this.isEqual(mToEntry, mTo))
                )
            ) {
                invalidCount++;
                timeEntry.is_valid = false;
            } else if (dayjs(timeEntry.date).isSame(dayjs(entry.date), 'day') &&
                (
                    this.getTotalMinutes(hFromEntry, mFromEntry) < this.getTotalMinutes(hTo, mTo) &&
                    this.getTotalMinutes(hToEntry, mToEntry) > this.getTotalMinutes(hFrom, mFrom)
                )
            ) {
                // if (start1 < end2 && end1 > start2) && same day
                invalidCount++;
                timeEntry.is_valid = false;
            }
        });

        return invalidCount;
    };


    /* ************************************* *
     *   ╔═══════════════════════════════╗   *
     *   ║   MISC FORMATTING FUNCTIONS   ║   *
     *   ╚═══════════════════════════════╝   *
     * ************************************* */

    formatCurrency(amount, infinite = false) {
        if (infinite && parseFloat(amount) === 0) { return '∞ $'; }
        if (amount === '') { return; }
        if (amount === undefined) { amount = 0; }

        let currency = new Intl.NumberFormat('fr-CA', { style: 'currency', currency: 'CAD' }).format(amount);

        if (amount < 0) {
            currency = `( ${currency.toString().replace('-', '')} )`;
        }

        return currency.toString().replace(',', '.');
    };

    round(value, decimals) {
        return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
    };

    displayRequisitionCode(code) {
        return this.padStartWith(code, 2, '0');
    };

    formatPhoneNumber(tel) {
        if (tel !== '' && tel !== null) {
            if (tel.length === 11) {
                return tel.substring(0, 1) + ' (' + tel.substring(1, 4) + ') ' + tel.substring(4, 7) + '-' + tel.substring(7);
            } else if (tel.length >= 12) {
                return tel.substring(0, tel.length - 10) + ' ' + tel.substring(tel.length - 10, tel.length - 6) + ' ' + tel.substring(tel.length - 6);
            } else {
                return '(' + tel.substring(0, 3) + ') ' + tel.substring(3, 6) + '-' + tel.substring(6);
            }
        } else {
            return tel;
        }
    };

    formatNumber(number) {
        numeral.localeData().delimiters.thousands = ' ';
        return numeral(number).format('0,0.[00]');
    };

    padStartWith(value, length, char) {
        return value?.toString().padStart(length, char);
    };

    /* ***************************** *
     *   ╔═══════════════════════╗   *
     *   ║   GENERAL FUNCTIONS   ║   *
     *   ╚═══════════════════════╝   *
     * ***************************** */

    getSumFromArray(array, filter) {
        return this.round(array?.reduce((acc, item) => {
            if (item[filter]) {
                return acc + Number(item[filter]);
            } else {
                return acc;
            }
        }, 0), 2);
    };

    generateRandomId() {
        return String.fromCharCode(97 + Math.floor(Math.random() * 26)).concat(Math.random().toString(36).substr(2, 12));
    };

    isEqual = (from, to) => {
        return from === to;
    };

    orderBy(array = [], keys, orders = 'asc') {
        // Convert keys to an array if it's a string
        if (!Array.isArray(keys)) {
            keys = [keys];
        }

        // If orders is not an array, convert it to an array with the same length as keys
        if (!Array.isArray(orders)) {
            orders = Array(keys.length).fill(orders);
        }

        const getValue = (obj, key) => {
            if (typeof key === 'function') {
                return key(obj); // If key is a function, invoke it
            }
            return key.split('.').reduce((o, k) => o && o[k], obj); // Handle nested keys if key is a string
        };

        return array.slice().sort((a, b) => {
            for (let i = 0; i < keys.length; i++) {
                const order = orders[i] === 'desc' ? -1 : 1;
                const aValue = getValue(a, keys[i]);
                const bValue = getValue(b, keys[i]);
            
                if (aValue === null && bValue !== null) return 1 * order;
                if (aValue !== null && bValue === null) return -1 * order;

                if (aValue !== bValue) {
                    return (aValue > bValue ? 1 : -1) * order;
                }
            }
            
            return 0;
          });
    };

    orderByCustom(list, variable, isNormalized = false) {
        if (list === null) {
            return [];
        }
        list = this.flipEmployeeNames(list, variable);

        const sortBy = (obj) => obj[variable].normalize('NFD').replace(/[\u0300-\u036f]|'/g, '').toLocaleLowerCase();
        return this.orderBy(list, isNormalized && typeof variable !== 'object' ? sortBy : variable);
    };

    uniqBy(array, iteratee) {
        const seen = new Set();
        return array.filter(item => {
            const key = (typeof iteratee === 'function') 
                ? iteratee(item) 
                : iteratee.split('.').reduce((obj, prop) => obj && obj[prop], item);

            if (seen.has(key)) {
                return false;
            } else {
                seen.add(key);
                return true;
            }
        });
    };

    flipEmployeeNames(list, variable) {
        if ((list !== undefined && list !== null) && variable === 'full_name') {
            const neededKeys = ['last_name', 'first_name', 'full_name'];
            const flipText = useRememberStore().getFieldValue('PersonalSettings', 'isTextFlipped');

            if (Array.isArray(list)) {
                list.forEach((item) => {
                    if (neededKeys.every(key => key in item)) {
                        item.full_name = flipText ? `${item.first_name ?? ''} ${item.last_name ?? ''}` : `${item.last_name ?? ''}${item.last_name !== null ? ',' : ''} ${item.first_name ?? ''}`;
                    }
                });
            } else {
                list.full_name = flipText ? `${list.first_name ?? ''} ${list.last_name ?? ''}` : `${list.last_name ?? ''}${list.last_name !== null ? ',' : ''} ${list.first_name ?? ''}`;
            }
        }
        return list;
    }

    removeProperties(object, properties) {
        return this.omit(object, properties);
    };

    omit(obj, keys) {
        const result = {};
        for (const key in obj) {
            if (!keys.includes(key)) {
                result[key] = obj[key];
            }
        }
        return result;
    };

    debounce(func, wait, immediate = false) {
        let timeout;
      
        return function(...args) {
            const context = this;
        
            const later = () => {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
        
            const callNow = immediate && !timeout;
        
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
        
            if (callNow) func.apply(context, args);
        };
    };

    filterIsFiled() {
        return 1111;
    };

    filterIsArchived() {
        return 9999;
    }
}