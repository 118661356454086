import { createRouter, createWebHistory } from 'vue-router'
import { useAuthUserStore } from "@/store/auth.js";
import { usePermissionStore } from "@/store/perms.js";
import { useConstantStore } from "@/store/constants.js";
import { useMemoStore } from "@/store/memo.js";
import { useBadgeStore } from '../store/badge';

const routes = [
    {
        path: '/',
        name: 'Login',
        component: () => import('@/components/login/Login.vue'),
        meta: {
            hideNavbar: true,
            permission_requirement: undefined,
            theme: 'general',
        },
    },
    {
        path: '/forbidden',
        name: '_403',
        component: () => import('@/components/_shared/_403.vue'),
        meta: {
            permission_requirement: undefined,
            theme: 'general',
        },
    },
    {
        path: '/undefined',
        name: '_404',
        component: () => import('@/components/_shared/_404.vue'),
        meta: {
            permission_requirement: undefined,
            theme: 'general',
        },
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import('@/components/home/Home.vue'),
        meta: {
            permission_requirement: undefined, //'can_view_home'
            theme: 'general',
        },
    },
    {
        path: '/cie',
        name: 'Cie List',
        component: () => import('@/components/cie/CieList.vue'),
        meta: {
            permission_requirement: 'can_view_cie',
            theme: 'general',
        },
    },
    {
        path: '/ciecontact/:id',
        name: 'Cie Contact',
        component: () => import('@/components/cie/CieContact.vue'),
        meta: {
            permission_requirement: 'can_view_cie',
            theme: 'general',
        },
    },
    {
        path: '/contactbook/',
        name: 'Contact Book',
        component: () => import('@/components/contact/ContactBook.vue'),
        meta: {
            permission_requirement: 'can_view_cie',
            theme: 'general',
        },
    },
    {
        path: '/workreport',
        name: 'Work Report',
        component: () => import('@/components/rt/RTList.vue'),
        meta: {
            permission_requirement: 'can_view_module_rt',
            theme: 'general',
        },
    },
    {
        path: '/approval',
        name: 'Approval List',
        component: () => import('@/components/approval/ApprovalList.vue'),
        meta: {
            permission_requirement: 'can_view_approval',
            theme: 'operation',
        },
    },
    {
        path: '/approval/:projectId',
        name: 'Approval',
        component: () => import('@/components/approval/Approval.vue'),
        meta: {
            permission_requirement: ['can_approve_projects', 'can_approve_other_projects'],
            theme: 'operation',
        },
    },
    {
        path: '/project',
        name: 'Project List',
        component: () => import('@/components/project/ProjectList.vue'),
        meta: {
            permission_requirement: 'can_view_project_page',
            theme: 'operation',
        },
    },
    {
        path: '/project/:id',
        name: 'Project',
        component: () => import('@/components/project/Project.vue'),
        meta: {
            permission_requirement: 'can_view_project_page',
            theme: 'operation',
        },
    },
    {
        path: '/project/archived/:id',
        name: 'ProjectArchived',
        component: () => import('@/components/project/ProjectArchived.vue'),
        meta: {
            permission_requirement: 'can_view_project_page',
            theme: 'operation',
        },
    },
    {
        path: '/material',
        name: 'Material List',
        component: () => import('@/components/material/MaterialList.vue'),
        meta: {
            permission_requirement: 'can_view_materiel',
            theme: 'operation',
        },
    },
    {
        path: '/material/:requisitionId/:taskId/:isReadOnly?',
        name: 'Material',
        component: () => import('@/components/material/Material.vue'),
        meta: {
            permission_requirement: ['can_view_materiel', 'can_view_materiel_histo'],
            theme: 'operation',
        },
    },
    {
        path: '/materialhistoric',
        name: 'Material Historic',
        component: () => import('@/components/material/MaterialHistoricList.vue'),
        meta: {
            permission_requirement: 'can_view_materiel_histo',
            theme: 'operation',
        },
    },
    {
        path: '/parts',
        name: 'Part List',
        component: () => import('@/components/parts/PartList.vue'),
        meta: {
            permission_requirement: 'can_view_parts',
            theme: 'operation',
        },
    },
    {
        path: '/brand',
        name: 'Brand List',
        component: () => import('@/components/parts/BrandList.vue'),
        meta: {
            permission_requirement: 'can_view_brands',
            theme: 'operation',
        },
    },
    {
        path: '/categories',
        name: 'Category List',
        // component: () => import('@/components/parts/CategoryList.vue'),
        component: () => import('@/components/_shared/_404.vue'),
        meta: {
            permission_requirement: 'can_view_categories',
            theme: 'operation',
        },
    },
    {
        path: '/inventory',
        name: 'Inventory',
        component: () => import('@/components/inventory/InventoryList.vue'),
        meta: {
            permission_requirement: 'can_view_inventory',
            theme: 'operation',
        },
    },
    {
        path: '/inventorylocations',
        name: 'Inventory Locations',
        component: () => import('@/components/inventory/InventoryLocations.vue'),
        meta: {
            permission_requirement: 'can_edit_inventory_locations',
            theme: 'operation',
        },
    },
    {
        path: '/immobilisation',
        name: 'Immobilisations',
        component: () => import('@/components/inventory/Immobilisation.vue'),
        meta: {
            permission_requirement: 'can_view_inventory',
            theme: 'operation',
        },
    },
    {
        path: '/supplierprice',
        name: 'Supplier Price List',
        component: () => import('@/components/supplier_price/SupplierPriceList.vue'),
        meta: {
            permission_requirement: 'can_view_pf',
            theme: 'operation',
        },
    },
    {
        path: '/supplierprice/:supplierPriceId',
        name: 'Supplier Price',
        component: () => import('@/components/supplier_price/SupplierPrice.vue'),
        meta: {
            permission_requirement: ['can_create_pf', 'can_edit_pf'],
            theme: 'operation',
        },
    },
    {
        path: '/shipping',
        name: 'Shipping List',
        component: () => import('@/components/shipping/ShippingList.vue'),
        meta: {
            permission_requirement: 'can_view_expedition',
            theme: 'operation',
        },
    },
    {
        path: '/shipping/:requisitionId/:materialOperationId?',
        name: 'Shipping',
        component: () => import('@/components/shipping/Shipping.vue'),
        meta: {
            permission_requirement: 'can_edit_shipping',
            theme: 'operation',
        },
    },
    {
        path: '/assembly/:requisitionId/:materialOperationId?',
        name: 'Assembly',
        component: () => import('@/components/shipping/Assembly.vue'),
        meta: {
            permission_requirement: 'can_edit_assembly',
            theme: 'operation',
        },
    },
    {
        path: '/shippingreturn',
        name: 'Shipping Returns List',
        component: () => import('@/components/shipping/ReturnShippingList.vue'),
        meta: {
            permission_requirement: 'can_view_expedition',
            theme: 'operation',
        },
    },
    {
        path: '/shippingreturn/:requisitionId/:materialOperationId?',
        name: 'Shipping Returns',
        component: () => import('@/components/shipping/ReturnShipping.vue'),
        meta: {
            permission_requirement: 'can_edit_shipping',
            theme: 'operation',
        },
    },
    {
        path: '/orderexternal',
        name: 'Order List BE',
        component: () => import('@/components/order/OrderList.vue'),
        meta: {
            permission_requirement: 'can_view_be',
            theme: 'operation',
            is_be: true,
        },
    },
    {
        path: '/order',
        name: 'Order List',
        component: () => import('@/components/order/OrderList.vue'),
        meta: {
            permission_requirement: 'can_view_orders',
            theme: 'supplying',
        },
    },
    {
        path: '/order/:materialOperationId',
        name: 'Order',
        component: () => import('@/components/order/Order.vue'),
        meta: {
            permission_requirement: 'can_edit_orders',
            theme: 'supplying',
        },
    },
    {
        path: '/orderexternal/:materialOperationId',
        name: 'Order BE',
        component: () => import('@/components/order/Order.vue'),
        meta: {
            permission_requirement: 'can_view_be',
            theme: 'operation',
            is_be: true,
        },
    },
    {
        path: '/reception/:materialOperationId',
        name: 'Reception',
        component: () => import('@/components/order/Reception.vue'),
        meta: {
            permission_requirement: 'can_edit_orders',
            theme: 'supplying',
        },
    },
    {
        path: '/receptionexternal/:materialOperationId',
        name: 'Reception BE',
        component: () => import('@/components/order/Reception.vue'),
        meta: {
            permission_requirement: 'can_view_be',
            theme: 'operation',
            is_be: true,
        },
    },
    {
        path: '/orderreturn',
        name: 'Order Returns List',
        component: () => import('@/components/order/ReturnOrderList.vue'),
        meta: {
            permission_requirement: 'can_view_orders',
            theme: 'supplying',
        },
    },
    {
        path: '/orderreturn/:materialOperationId/:materialOperationType?',
        name: 'Order Returns',
        component: () => import('@/components/order/ReturnOrder.vue'),
        meta: {
            permission_requirement: 'can_edit_orders',
            theme: 'supplying',
        },
    },
    {
        path: '/bill',
        name: 'Bill',
        component: () => import('@/components/bill/BillList.vue'),
        meta: {
            permission_requirement: 'can_view_billing',
            theme: 'administration',
        },
    },
    {
        path: '/bill/:projectId',
        name: 'BillPoList',
        component: () => import('@/components/bill/BillPoList.vue'),
        meta: {
            permission_requirement: 'can_view_billing',
            theme: 'administration',
        },
    },
    {
        path: '/bill/po/:poId/:mfId?',
        name: 'BillPo',
        component: () => import('@/components/bill/Bill.vue'),
        meta: {
            permission_requirement: 'can_view_billing',
            theme: 'administration',
        },
    },
    {
        path: '/pay/:payPeriod?',
        name: 'Pay',
        component: () => import('@/components/pay/PayList.vue'),
        meta: {
            permission_requirement: 'can_view_payroll',
            theme: 'administration',
        },
    },
    {
        path: '/pay/:payPeriodId/:employeId',
        name: 'Pay Details',
        component: () => import('@/components/pay/PayDetails.vue'),
        meta: {
            permission_requirement: 'can_edit_payroll',
            theme: 'administration',
        },
    },
    {
        path: '/expense/:payPeriod?',
        name: 'Expense',
        component: () => import('@/components/expense/ExpenseList.vue'),
        meta: {
            permission_requirement: 'can_view_expenses',
            theme: 'administration',
        },
    },
    {
        path: '/expense/:payPeriodId/:employeId',
        name: 'Expense Details',
        component: () => import('@/components/expense/ExpenseDetails.vue'),
        meta: {
            permission_requirement: 'can_edit_expenses',
            theme: 'administration',
        },
    },
    {
        path: '/taxes',
        name: 'Taxes',
        component: () => import('@/components/expense/taxes/TaxList.vue'),
        meta: {
            permission_requirement: 'can_edit_taxes',
            theme: 'administration',
        },
    },
    {
        path: '/credit',
        name: 'Credit',
        component: () => import('@/components/expense/CreditList.vue'),
        meta: {
            permission_requirement: 'can_view_credit',
            theme: 'administration',
        },
    },
    {
        path: '/credit/:accountId',
        name: 'Credit Details',
        component: () => import('@/components/expense/CreditDetails.vue'),
        meta: {
            permission_requirement: 'can_view_credit',
            theme: 'administration',
        },
    },
    {
        path: '/report/occupation',
        name: 'Occupation Report',
        component: () => import('@/components/report/Occupation.vue'),
        meta: {
            permission_requirement: 'can_view_report_occupation',
            theme: 'administration',
        },
    },
    {
        path: '/report/billing',
        name: 'Billing Report',
        component: () => import('@/components/report/Billing.vue'),
        meta: {
            permission_requirement: 'can_view_report_billing',
            theme: 'administration',
        },
    },
    {
        path: '/report/suiviPO',
        name: 'Suivi Po',
        component: () => import('@/components/report/PoReport.vue'),
        meta: {
            permission_requirement: 'can_view_report_suivi_po',
            theme: 'administration',
        },
    },
    {
        path: '/report/receivable',
        name: 'Receivable Report',
        component: () => import('@/components/report/ReceivableReport.vue'),
        meta: {
            permission_requirement: 'can_view_report_receivable',
            theme: 'administration',
        },
    },
    {
        path: '/report/workedHours',
        name: 'Worked Hours Report',
        component: () => import('@/components/report/WorkedHoursReport.vue'),
        meta: {
            permission_requirement: 'can_view_report_hours_work',
            theme: 'administration',
        },
    },
    {
        path: '/report/partsmonitoring',
        name: 'Parts Monitoring List',
        component: () => import('@/components/report/PartsMonitoringList.vue'),
        meta: {
            permission_requirement: 'can_view_report_parts_monitoring',
            theme: 'administration',
        },
    },
    {
        path: '/report/partsmonitoring/:id',
        name: 'Parts Monitoring',
        component: () => import('@/components/report/PartsMonitoring.vue'),
        meta: {
            permission_requirement: 'can_view_report_parts_monitoring',
            theme: 'administration',
        },
    },
    {
        path: '/report/currentProjects',
        name: 'Current Projects Report',
        component: () => import('@/components/report/CurrentProjectsReport.vue'),
        meta: {
            permission_requirement: 'can_view_report_current_projects',
            theme: 'administration',
        },
    },
    {
        path: '/report/tec',
        name: 'Tec List',
        component: () => import('@/components/report/tec/TecList.vue'),
        meta: {
            permission_requirement: 'can_view_report_tec',
            theme: 'administration',
        },
    },
    {
        path: '/report/tec/:id',
        name: 'Tec',
        component: () => import('@/components/report/tec/Tec.vue'),
        meta: {
            permission_requirement: 'can_view_report_tec',
            theme: 'administration',
        },
    },
    {
        path: '/report/techours',
        name: 'TEC Hours List',
        component: () => import('@/components/report/tec_hours/TECHoursList.vue'),
        meta: {
            permission_requirement: 'can_view_report_tec_hours',
            theme: 'administration',
        },
    },
    {
        path: '/report/techours/:id',
        name: 'TEC Hours',
        component: () => import('@/components/report/tec_hours/TECHours.vue'),
        meta: {
            permission_requirement: 'can_view_report_tec_hours',
            theme: 'administration',
        },
    },
    {
        path: '/dev',
        name: 'Dev',
        component: () => import('@/components/_shared/_404.vue'),
        meta: {
            permission_requirement: undefined,
            theme: 'general',
        },
    },
    {
        path: '/UpdateReportsAmounts',
        name: '/UpdateReportsAmounts',
        component: () => import('@/components/dev/UpdateReportsAmounts.vue'),
        meta: {
            permission_requirement: undefined,
            theme: 'general',
        },
    },
    {
        path: '/UpdateExpenseTotal',
        name: '/UpdateExpenseTotal',
        component: () => import('@/components/dev/UpdateExpenseTotal.vue'),
        meta: {
            permission_requirement: undefined,
            theme: 'general',
        },
    },
    {
        path: '/UpdateApprovalListHourApproved',
        name: '/UpdateApprovalListHourApproved',
        component: () => import('@/components/dev/UpdateApprovalListHourApproved.vue'),
        meta: {
            permission_requirement: undefined,
            theme: 'general',
        },
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/components/dashboard/Dashboard.vue'),
        meta: {
            permission_requirement: 'can_view_dashboard',
            theme: 'general',
        },
    },
    {
        path: '/ArchiveProjects',
        name: 'ArchiveProjects',
        component: () => import('@/components/dev/ArchiveProjects.vue'),
        meta: {
            permission_requirement: undefined,
            theme: 'general',
        },
    },
    {
        path: '/employe',
        name: 'Employe',
        component: () => import('@/components/employe/EmployeList.vue'),
        meta: {
            permission_requirement: 'can_view_employees',
            theme: 'general',
        },
    },
    {
        path: '/billingmemo',
        name: 'Billing Memo',
        component: () => import('@/components/billing_memo/BillingMemo.vue'),
        meta: {
            permission_requirement: 'can_view_mf',
            theme: 'general',
        },
    },
    {
        path: '/password',
        name: 'Password',
        component: () => import('@/components/_shared/_404.vue'),
        meta: {
            permission_requirement: 'reset_user_password',
            theme: 'general',
        },
    },
    {
        path: '/bug',
        name: 'Bug',
        component: () => import('@/components/_shared/Bug.vue'),
        meta: {
            permission_requirement: undefined,
            theme: 'general',
        },
    },
    {
        path: '/doc',
        name: 'Documentation',
        component: () => import('@/components/_shared/Documentation.vue'),
        meta: {
            permission_requirement: 'can_view_doc',
            theme: 'general',
        },
    },
    {
        path: '/params',
        name: 'General Params',
        component: () => import('@/components/_shared/_404.vue'),
        meta: {
            permission_requirement: 'can_view_config',
            theme: 'general',
        },
    },
    {
        path: '/role',
        name: 'Role',
        component: () => import('@/components/role/Role.vue'),
        meta: {
            permission_requirement: 'can_edit_roles',
            theme: 'general',
        },
    },
    {
        path: '/annualrate',
        name: 'Annual Rate',
        component: () => import('@/components/annualrate/AnnualRate.vue'),
        meta: {
            permission_requirement: 'can_edit_annual_rate',
            theme: 'general',
        },
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    linkActiveClass: 'navbar-selected',
    linkExactActiveClass: 'navbar-selected',
})

router.beforeEach(async (to, from) => {
    const authUserStore = useAuthUserStore();
    const permissionStore = usePermissionStore();
    const constantStore = useConstantStore();
    const memoStore = useMemoStore();
    const badgeStore = useBadgeStore();

    if (authUserStore.isLoggedIn) {
        memoStore.getAllMemos();
        badgeStore.getAllBadges();
        authUserStore.updateCurrentRoute(to.name);
    }

    if (!authUserStore.isLoggedIn && to.name !== 'Login') {
        return { name: 'Login' }
    }

    if (to.meta.permission_requirement !== undefined && !permissionStore.canAccess(to.meta.permission_requirement)) {
        return { name: '_403' }
    }

    if (from.name === 'Login' && to.name === 'Home') {
        constantStore.getConstants();
    }
})

export default router